import styled from "styled-components"

const Container=styled.div`
height:30px;
background-color:#0F2C59;
align-items:center;
justify-content:center;
display:flex;
`
const Sale= styled.div`
font-weight:400;
color:white;
align-items:center;
justify-content:center;
font-size:14px;

`

const announcement = () => {
  return (
    <div>
      <Container>
        <Sale>Flash Sale!!!Free Shipping on orders over $50</Sale>
      </Container>
    </div>
  )
}

export default announcement
