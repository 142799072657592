
import products from "./products";
import styled from "styled-components";


import { FavoriteBorderOutlined, SearchOutlined, ShoppingCartCheckoutOutlined } from "@mui/icons-material";


const Info=styled.div`
opacity:0;
width:100%;
height:100%;
position:absolute;
top:0;
left:0;

background-color:(0,0,0,0.2);
display:flex;
align-items:center;
justify-content:center;
z-index:3;
transition: all 0.5s ease;
cursor: pointer;
`

const Container=styled.div`
flex:1;
margin:5px;
min-width:280px;
height:350px;
display:flex;
align-items:center;
justify-content:center;
background-color:white;
position:relative;
&:hover ${Info}{
    opacity:1;
}
`
const Circle=styled.div`
height:300px;
width:300px;
border-radius:50%;
background-color:#F2C9B5;
position:absolute;
`
const Image=styled.img`
height:75%;
width:70%;
z-index:2;

`

const Icon=styled.div`
width:40px;
height:40px;
border-radius:50%;
background-color:#ffe4d6;
display:flex;
align-items:center;
justify-content:center;
margin:10px;
transition:all 0.5s ease;
&:hover{
    background-color:#e9f5f5;
    transform: scale(1.1);
}
`


const Product = ({item}) => {
  return (
   <Container>

<Image src={item.img}/>
<Info>
    <Icon>
        <ShoppingCartCheckoutOutlined/>
    </Icon>
    <Icon>
        <SearchOutlined/>
    </Icon>
    <Icon>
        <FavoriteBorderOutlined/>
    </Icon>
</Info>
   </Container>
  )
}

export default Product
