import "./home.css"
import Navbar from "./../components/navbar"
import Announcement from "./../components/announcement"
import Slider from "./../components/slider"
import  Categories  from "../../src/components/categories"
import Products from "../components/products";
import Newsletter from "../components/newsletter"
import Footer from "../components/footer"
import {

  Routes,
  Route
} from "react-router-dom";

import Cart from "./cart"
import Login from "./login"
import Register from "./register"

import { Link } from 'react-router-dom';

const home = () => {
  return (
    <div>
    <Announcement/>
   <Navbar/>
   <Slider/>
   <Categories/>
   <Products/>
   <Newsletter/>
   <Footer/>
  
    </div>
  )
}

export default home
