
import React from 'react'
import styled from "styled-components";
import SendIcon from '@mui/icons-material/Send';
import {mobile} from "../responsive"

const Container=styled.div`
background-color:#FFE4D6;
display:flex;
flex-direction:column;
width:100%;
height:70vh;
align-items:center;
justify-content:center;
`
const Title=styled.h1`
font-size:70px;
margin-top:20px;
justify-content:center;
text-align:center;
padding:30px;



`
const Description1=styled.div`
display:flex;
justify-content:center;
text-align:center;
font-weight:400;
font-size:24px;
margin-bottom:20px
${mobile({textAlign:"center"})}

`
const InputContainer=styled.div`
width:50%;
height:40px;
background-color:white;
display:flex;
justify-content:space-between; 
border:1px solid lightgray;
${mobile({width:"80%"})}
`
const Input=styled.input`
border:none;
flex:8;
padding:20px;
`
const Button=styled.button`
background-color:#0f2c59;
color:white;
flex:1; 
cursor:pointer;

`

const newsletter = () => {
  return (
    <Container>
<Title>Newsletter</Title>
<Description1>Get Timely Updates from your favourite products.</Description1>
<InputContainer>
    <Input type="email" placeholder='Your Email '></Input>
    <Button><SendIcon/></Button>
</InputContainer>
    </Container>
  )
}

export default newsletter
