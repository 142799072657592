import Product from "./product";
import { popularProducts } from "../data";
import styled from "styled-components";
const Container=styled.div`
display:flex;
padding:20px;
flex-wrap:wrap;
justify-content:space-between;
`
const Products = () => {
  return (
    <Container>
{popularProducts.map((item)=>(
    <Product item={item} key={item.id}/>
))}
    </Container>
  )
}

export default Products
