
import styled from "styled-components";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import PinterestIcon from '@mui/icons-material/Pinterest';
import {mobile} from "../responsive"

const Container=styled.footer`
display:flex;
background-color::#F4F0EE;
position: absolute;
${mobile({flexDirection:"column"})}
`
const Left=styled.div`
flex:1;
import { Height } from '@mui/icons-material';
dislay:flex;
flex-direction:column;
padding:20px;
`
const Logo=styled.h1``
const Desc=styled.p`
margin:20px 0;
`
const SocialContainer=styled.div`
display:flex;
`;
const SocialIcon=styled.div`
height:30px;
width:30px;
border-radius:50%;
color:white;
background-color:${props=>props.color};
display:flex;
align-items:Center;
justify-content:center;
margin-right:10px;

`


const Center=styled.div`
flex:1;
padding: 20px;
${mobile({display:"none"})}
`
const Title=styled.h3`
margin-bottom:20px;
`

const List=styled.ul`
list-style-type:none;
flex-wrap:wrap;
display:flex;

margin:0;
padding:0;
`
const ListItem=styled.li`

width:50%;
margin-bottom:10px;
`
const Right=styled.div`
flex:1;
padding: 20px;

`
const Contact=styled.h3`
margin-bottom:10px;
`
const Wrapper=styled.div`
margin-bottom:20px;
justify-content:center;
align-items:center;

`
const Payment=styled.img`
width:50%;
Height:40px;

`
const Footer = () => {
  return (
     <Container>
<Left>         
  <Logo>
  Star Dust.
   </Logo>
   <Desc>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
    Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, 
    when an unknown printer took a galley of type and scrambled it to make a type specimen book. </Desc>
<SocialContainer>
<SocialIcon color="#0866ff"><FacebookIcon/> </SocialIcon>
<SocialIcon color="#e34762"> <InstagramIcon/> 
</SocialIcon>
<SocialIcon color="#1da1f2"> <TwitterIcon/> </SocialIcon>
<SocialIcon color="#b7081b">  <PinterestIcon/> </SocialIcon>

</SocialContainer>
</Left>
<Center>
<Title>Useful Links</Title>
<List>
  <ListItem>Home</ListItem>
  <ListItem>Cart</ListItem>
  <ListItem>Man Fashion</ListItem>
  <ListItem>Women Fashion</ListItem>
  <ListItem>Accesorries</ListItem>
  <ListItem>My Account</ListItem>
  <ListItem>Order Tracking</ListItem>
  <ListItem>WishList</ListItem>
  <ListItem>WishList</ListItem>
  <ListItem>Terms</ListItem>

</List>
</Center>
<Right>
<Contact>Contact</Contact>

<Wrapper>  <LocationOnIcon style={{marginRight: "10px"}}/>10 Downing street, London</Wrapper>
<Wrapper> <PhoneIcon style={{marginRight: "10px"}}/> 07945698</Wrapper>
<Wrapper> <EmailIcon style={{marginRight: "10px"}}/>contactat@gmail.com</Wrapper>
<Payment src="https://help.zazzle.com/hc/article_attachments/360010513393/Logos-01.png" />
</Right> 
    </Container>
  )
}
export default Footer;

