import styled from "styled-components"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import { sliderItems } from "../data";
import { useState } from "react";
import {mobile} from "../responsive"

const Container=styled.div`
width:100%;
height:100vh;
background-color:#E7E0C9;
display:flex;
position:relative;
overflow:hidden;
${mobile({display:"none"})}

`
const Arrow=styled.div`
width:20px;
height:20px;


display:flex;
border-radius:50px;
align-items:center;
justify-content:center;
position:absolute;
top:0;
bottom:0;
left:${props=> props.direction  === "left" && "30px"};
right:${props=>  props.direction  === "right" && "30px"};
margin:auto;
cursor: pointer;
opacity:0.5;
z-index:2;
`
const Wrapper=styled.div`
height:100%;
display:flex;
background-color:#E7E0C9;
transform:translateX(${props=>props.slideIndex * -100}vw);
transition: all 2s ease-in;
`

const Slide=styled.div`
width:100vw;
height:100vh;
display:flex;
align-items:center;
background-color:#${props=>props.bg}

`
const ImgContainer=styled.div`

padding-top:50px

height:100vh;
align-items:center;
flex:1;
`

const Image=styled.img`
object-fit:contain;
height:80%;
opacity:1;




`;
const InfoContainer=styled.div`
padding:50px;
flex:1;
`;
const Title=styled.h1`
font-size:70px;
`;
const Desc=styled.p`
margin:50px 0px;
font-size:20px;
font-weight:500; 
letter-spacing:3px;
`
const Button=styled.button`
padding:10px;
font-size:20px;
background-color:#11324D;
color:white;
cursor:pointer;
`
const Slider = () => {
  
const [slideIndex, setSlideIndex]=useState(0);   
  const handleClick=(direction) => {

    if(direction==="left"){
      setSlideIndex(slideIndex > 0 ? slideIndex-1 : 2);
    }
    else
    {
      setSlideIndex(slideIndex < 2 ? slideIndex +1 :0 );
    }
  };
  return (
 
      <Container>
        <Arrow direction="left" onClick={()=>handleClick("left")} > <ArrowBackIcon/>
</Arrow>
<Wrapper slideIndex={slideIndex}>

{sliderItems.map((item) =>(
  <Slide bg={item.bg} key={item.id}>
<ImgContainer>
  <Image src={item.img}/></ImgContainer>

   <InfoContainer><Title>{item.title}</Title>
   <Desc>{item.desc}
   </Desc>
   <Button>
   Shop Now
   </Button></InfoContainer></Slide>))}
   
  
</Wrapper>
      <Arrow direction="right" onClick={()=>handleClick("right")} >
      <ArrowForwardIcon/>
        </Arrow>
      </Container>
  
  )
}

export default Slider
