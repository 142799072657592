import Badge from '@mui/material/Badge';

import { Search,ShoppingCartOutlined  } from '@mui/icons-material'
import React from 'react'
import styled from 'styled-components'
import {mobile} from "../responsive"

import {

  Routes,
  Route
} from "react-router-dom";
import { Link } from 'react-router-dom';
import Home from "../pages/home"
import Cart from "../pages/cart"
import Register from "../pages/register"
import Login from "../pages/login"


const Container=styled.div`
height:60px;
${mobile({height:"50px"})}

`;
const Wrapper=styled.div`
padding:10px 20px;
display:flex;

justify-content:space-between;
align-items:center;
${mobile({padding:"10px 0px"})}
`


const Left= styled.div
`flex:1;
display:flex;

`
/*
const language = styled.span `
font-size:14px;
cursor:pointer;
${mobile({display:"none"})}


`*/

const SerachContainer= styled.div`
border: 0.5px solid lightgray;
display:flex;
align-items:center;
margin-left:25px;
padding:5px;
`
const Input= styled.input`
border:none;
${mobile({width:"50px"})}
`
const Centre= styled.div`
flex:1;
text-align:center;
`
const Logo=styled.h1`
font-weight:bold;
text-decoration-line: none;
${mobile({fontSize:"12px"})}
`
const Right= styled.div`
flex:1;
display:flex;
align-items:center;
justify-content:flex-end;
${mobile({justifyContent:"center",flex:2})}
`
const MenuItem=styled.div`
font-size:14px;
cursor:pointer;
margin-left:25px;
${mobile({fontSize:"10px",marginLeft:"5px"})}
`


const navbar = () => {
  return (
    <div>
   <Container>
   <Wrapper> <Left>
  
   <SerachContainer>
  <Input/> 
  <Search style={{color:"gray",fontSize: "16px"}} />
   </SerachContainer>
   </Left>
   <Centre><Logo><Link to="/home">Star Dust.</Link></Logo></Centre>
   <Right><MenuItem> <Link to="/register">Register</Link></MenuItem>
   <MenuItem><Link to="/login">Sign In</Link></MenuItem>
   <MenuItem>
   <Badge badgeContent={4} color="primary">
 <ShoppingCartOutlined><Link to="/Cart"></Link></ShoppingCartOutlined>
</Badge>
   </MenuItem>
   </Right></Wrapper>
   
   </Container>
   <Routes>
    
    <Route path="cart" element={<Cart />} />
    <Route path="register" element={<Register />} />
    <Route path="login" element={<Login />} />

</Routes>
    </div>
    
  )
}

export default navbar
