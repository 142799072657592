export const sliderItems=[
    {
        id:1,
        img:"https://images.pexels.com/photos/1004642/pexels-photo-1004642.jpeg?auto=compress&cs=tinysrgb&w=600",
        title:"Summer Sale",
        desc:"Donot compromise on style!!!Free Super Saver Delivery On All Orders Over £15",
        bg:"FFE4D6",
    },
    {
        id:2,
        img:"https://images.pexels.com/photos/2887767/pexels-photo-2887767.jpeg?auto=compress&cs=tinysrgb&w=600",
        title:"SALE IS ON",
        desc:"Donot compromise on style!!!Free Super Saver Delivery On All Orders Over £15",
    
        bg:"C1CFC0",
       
    },
    {
        id:3,
        img:"https://images.pexels.com/photos/6866898/pexels-photo-6866898.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        title:"Winter Sale",
        desc:"Donot compromise on style!!!Free Super Saver Delivery On All Orders Over £15",
        bg:"#5f422f"

    },{
        id:4,
        img:"https://images.pexels.com/photos/1468379/pexels-photo-1468379.jpeg?auto=compress&cs=tinysrgb&w=600",
        title:"SALE IS ON",
        desc:"Donot compromise on style!!!Free Super Saver Delivery On All Orders Over £15",
        bg:"E7E0C9"
       
    },
    {
        id:5,
        img:"https://images.pexels.com/photos/991113/pexels-photo-991113.jpeg?auto=compress&cs=tinysrgb&w=600",
        title:"Winter Sale",
        desc:"Donot compromise on style!!!Free Super Saver Delivery On All Orders Over £15",
        bg:"#E7E0C9"

    }
]
export const categories =[
    { id:1,
        img:"https://images.pexels.com/photos/1377455/pexels-photo-1377455.jpeg?auto=compress&cs=tinysrgb&w=600",
        title:"Shirt style",

    },
    { id:2,
        img:"https://images.pexels.com/photos/991113/pexels-photo-991113.jpeg?auto=compress&cs=tinysrgb&w=600",
        title:"LoungeWear",

    }
,
{ id:3,
    img:"https://images.pexels.com/photos/1468379/pexels-photo-1468379.jpeg?auto=compress&cs=tinysrgb&w=600",
    title:"Light Jackets",

}


]
export const popularProducts=[
    {
        id:1,
        img:"https://images.pexels.com/photos/6044266/pexels-photo-6044266.jpeg?auto=compress&cs=tinysrgb&w=600"
    },
    {
        id:2,
        img:"https://images.pexels.com/photos/984619/pexels-photo-984619.jpeg?auto=compress&cs=tinysrgb&w=600"
    },
    {
        id:3,
        img:"https://images.pexels.com/photos/1381553/pexels-photo-1381553.jpeg?auto=compress&cs=tinysrgb&w=600"
    },
    
        {
            id:4,
            img:"https://images.pexels.com/photos/18533674/pexels-photo-18533674/free-photo-of-clothes-on-yellow-background.jpeg?auto=compress&cs=tinysrgb&w=600"
        },
        {
            id:5,
            img:"https://images.pexels.com/photos/6044266/pexels-photo-6044266.jpeg?auto=compress&cs=tinysrgb&w=600"
        },
        {
            id:6,
            
           img: "https://images.pexels.com/photos/2120584/pexels-photo-2120584.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
        },
        
    
        {
            id:7,
            img:"https://images.pexels.com/photos/3151954/pexels-photo-3151954.jpeg?auto=compress&cs=tinysrgb&w=600"
        },
        {
            id:8,
            img:"https://images.pexels.com/photos/1214212/pexels-photo-1214212.jpeg?auto=compress&cs=tinysrgb&w=600"
        },
        {
            id:9,
            img:"https://images.pexels.com/photos/2120584/pexels-photo-2120584.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        },
        {
            id:10,
            img:"https://images.pexels.com/photos/908530/pexels-photo-908530.jpeg?auto=compress&cs=tinysrgb&w=600",
            },
        
       
]
