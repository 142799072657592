
import styled from "styled-components"
import Navbar from "../components/navbar"
import Announcement  from "../components/announcement"
import Footer from "../components/footer"
import { Add } from "@mui/icons-material"
import { Remove } from "@mui/icons-material"
import {mobile} from "../responsive"
import { Link } from "react-router-dom"
const Container=styled.div``
const Wrapper=styled.div`

padding:20px;
${mobile({padding: "10px"})}
`
const Title=styled.h1`
font-weight:300;
text-align:center;`
const Top=styled.div`
display:flex;
align-items:center;
padding:20px;
justify-content:space-between;`
const TopButton=styled.button`
font-weight:600;
padding:10px;
cursor: pointer;
border:${props=>props.type === "filled" && "none"};
background-color:${props=>props.type === "filled" ? "#0F2C59" : "transparent"};
color:${props=>props.type === "filled" && "white"};`;
const TopTexts=styled.div`
${mobile({display: "none"})}`
const TopText=styled.span`
text-decoration:underline;

margin:0px 10px;`
const Bottom=styled.div`
display:flex;
justify-content:space-between;
${mobile({flexDirection: "column"})}
`
const Info=styled.div`
flex:3;
`

const Product=styled.div`
display:flex;
justify-content:space-between;`;
const ProductDetail=styled.div`
display:flex;
flex:2;
${mobile({flexDirection: "column"})}`;
const Image=styled.img`

width:200px;
height:150px;
`;
const Detail=styled.div`
padding:20px;
display:flex;
flex-direction:column;
justify-content:space-around;`;
const ProductName=styled.span``;
const ProductID=styled.span``;
const ProductColor=styled.div`
width:20px;
height:20px;
border-radius:50%;
background-color:${props=>props.color};
`;
const ProductSize=styled.span``;
const PriceDetail=styled.div`
flex:1;
display:flex;
flex-direction:column;
align-items:center;
justify-content:center;`;

const ProductAmountContainer=styled.div`
display:flex;
align-items:center;
margin-bottom: 20px;`;
const ProductAmount=styled.div`
font-size:24 px;
margin:5px;`;
const ProductPrice=styled.div`
font-size:30px;
font-weight:200;`;
const Hr=styled.hr`
background-color:gray;
border:none;
height:1px;
margin:10px;`
const Summary=styled.div`
flex:1;
border:0.5px solid #0F2C59;
border-radius:10px;
padding:20px;
height:50vh;

`
const SummaryTitle=styled.h2`
font-weight: 200;`;
const SummaryItem=styled.div`
margin: 30px 0px;
display:flex;
justify-content:space-between;
font-weight:${props=>props.type === "total" && "500"};
font-size:${props=>props.type==="total" && "24px"};
`;
const SummaryItemPrice=styled.span``;
const SummaryItemText=styled.span``;

const Button=styled.button`
width: 100%;
padding:10px;
background-color:#0F2C59;
color:white;
font-weight: 600;`;

const Cart = () => {
  return (
    <Container>
<Navbar/>
<Announcement/>
<Wrapper>
    <Title>Your Bag</Title>
    <Top>
<TopButton>Continue Shopping</TopButton>
<TopTexts>
    <TopText>Shopping Bag(2)
    </TopText>
    <TopText>Your Wishlist(0)</TopText>
</TopTexts>
<TopButton type="filled">CheckOut Now</TopButton>
    </Top>
    <Bottom>
        <Info>
        <Product>
        <ProductDetail>
        <Image src="https://images.unsplash.com/photo-1542291026-7eec264c27ff?auto=format&fit=crop&q=80&w=1470&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"></Image>
        <Detail>
        <ProductName><b>Product:</b> Thunder Shoes</ProductName>
        <ProductID><b>ID:</b> B162789</ProductID>
        <ProductColor color="red"></ProductColor>
        <ProductSize><b>Size:</b> 37.5</ProductSize>
       </Detail>
       </ProductDetail>
    
       <PriceDetail>Price
       <ProductAmountContainer>
        <Add/>
        <ProductAmount>
            2
        </ProductAmount>
        <Remove/>
       </ProductAmountContainer>
       <ProductPrice>$30</ProductPrice></PriceDetail>
        </Product>
        <Hr/>
        <Product>
        <ProductDetail>
        <Image src="https://images.unsplash.com/photo-1576566588028-4147f3842f27?auto=format&fit=crop&q=60&w=500&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTl8fHNoaXJ0fGVufDB8fDB8fHww"></Image>
        <Detail>
        <ProductName><b>Product:</b> Black short sleeve shirt</ProductName>
        <ProductID><b>ID:</b> B162792</ProductID>
        <ProductColor color="beige"></ProductColor>
        <ProductSize><b>Size:</b> L</ProductSize>
       </Detail>
       </ProductDetail>
    
       <PriceDetail>Price
       <ProductAmountContainer>
        <Add/>
        <ProductAmount>
            2
        </ProductAmount>
        <Remove/>
       </ProductAmountContainer>
       <ProductPrice>$20</ProductPrice></PriceDetail>
        </Product>
       
        </Info>
       
        <Summary><SummaryTitle>Order Summary</SummaryTitle>
        <SummaryItem>
            <SummaryItemText>SubTotal</SummaryItemText>
            <SummaryItemPrice>$50</SummaryItemPrice>
        </SummaryItem>
        <SummaryItem>
            <SummaryItemText>Estimated Shipping</SummaryItemText>
            <SummaryItemPrice>$5</SummaryItemPrice>
        </SummaryItem>
        <SummaryItem>
            <SummaryItemText>Shipping Discount</SummaryItemText>
            <SummaryItemPrice>-$5</SummaryItemPrice>
        </SummaryItem>
        <SummaryItem type="total">
            <SummaryItemText >Total</SummaryItemText>
            <SummaryItemPrice>$50</SummaryItemPrice>
            
        </SummaryItem>
        <Button>Order Now</Button>
        </Summary>
    </Bottom>
</Wrapper>
<Footer/>

    </Container> 
  )
}

export default Cart
