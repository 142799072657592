import styled from "styled-components"
import { Link } from "react-router-dom"
import {mobile} from "../responsive"
const Container=styled.div`
height:100vh;
width:100vw;

background: linear-gradient( #F5EFE7,#D8C4B6,#4F709C,#213555) , url("https://images.pexels.com/photos/3283576/pexels-photo-3283576.jpeg?auto=compress&cs=tinysrgb&w=600")center;

display:flex;
align-items:center;
justify-content:center;
`
const Wrapper=styled.div`
padding:20px;
width:40%;
background-color:#e4d5c9;

`
const Title=styled.h1`
font-size:24px;
font-weight:300;`
const Form=styled.form`
display:flex;
flex-wrap:wrap;`
const Input=styled.input`
flex:1;
min-width:40%;
margin:20px 10px 0px 0px;
padding:10px;
`
const Agreement=styled.span`
font-size:12px;
margin:20px 0px; `
const Button=styled.button`
 
width:40%;
border:none;
padding:15px 20px;
background-color:#2c4366;
color:white;
${mobile({width:"80%"})}


`



const register = () => {
  return (
    <Container>
<Wrapper>
  <Title>Create an Account</Title>
  <Form>
    <Input placeholder="name"/>
    <Input placeholder="last name"/>
    <Input placeholder="email"/>
    <Input placeholder="username"/>
    <Input placeholder="password"/>
    <Input placeholder="confirm password"/>
    <Agreement>By creating an account ,I consent to the processing of my personal data in accordance with the <b>privacy policy</b></Agreement>
  <Button>Create</Button>
  </Form>
</Wrapper>
    </Container>
  )
}

export default register
