import React from 'react'
import Home from "../src/pages/home"
import Cart from "../src/pages/cart"
import Login from "../src/pages/login"
import Register from "../src/pages/register"
import {

  Routes,
  Route
} from "react-router-dom";


const App = () => {
  return (
    <div>
     <Home/>
    
  
    </div>

  )
}

export default App
