import styled from "styled-components"
import { mobile } from "../responsive"

import { Link } from 'react-router-dom';


const Container=styled.div`
height:100vh;
width:100vw;

background: linear-gradient( #F5EFE7,#D8C4B6,#4F709C,#213555) , url("https://images.pexels.com/photos/3283576/pexels-photo-3283576.jpeg?auto=compress&cs=tinysrgb&w=600")center;

display:flex;
align-items:center;
justify-content:center;
${mobile({flexDirection:"column"})}
`
const Wrapper=styled.div`
padding:20px;
width:25%;
background-color:#e4d5c9;
${mobile({width:"75%"})}

`
const Title=styled.h1`
font-size:24px;
font-weight:300;`
const Form=styled.form`
display:flex;
flex-direction:column;`
const Input=styled.input`
flex:1;
min-width:40%;
margin:10px 0px;
padding:10px;
`

const Button=styled.button`
 
width:40%;
border:none;
padding:15px 20px;
background-color:#2c4366;
color:white;
margin-bottom:10px;
${mobile({width:"80%", justifyContent: "center"})}
`
const Link1=styled.a`
margin:5px 0px;
font-size:12px;
text-decoration:underline;
cursor: pointer;
justify-content:center;`;

const Login = () => {
  return (
    <Container>
<Wrapper>
  <Title>Sign In</Title>
  <Form>
    <Input placeholder="Username"/>
    <Input placeholder="Password"/>
    
    
  <Button>
  Login</Button>
  <Link1>Donot you remember the password</Link1>
  <Link1>Create a new account</Link1>
  </Form>
</Wrapper>

    </Container>
    
  )
}

export default Login
